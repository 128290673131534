<template>
  <div class="guest-reviews">
    <div class="guest-reviews__wrapper">
      <div v-if="isYandex">
        <Row :wrap="$viewport.mobile"
             justify="between">
          <Column class="guest-reviews__title" :class="$viewport.desktop ? 'grid-4' : ''">
            Отзывы гостей
          </Column>
          <Column class="guest-reviews__yandex-widget" :class="$viewport.desktop ? 'grid-8' : ''">
            <YandexComments ref="yandexWidget" @mounted="initYandexComments" />
          </Column>
        </Row>
      </div>
      <div v-else>
        <Row :wrap="$viewport.mobile"
             justify="between">
          <Column v-if="$token"
                  :class="$viewport.desktop ? 'grid-4' : ''"
                  :full="$viewport.mobile">
            <p v-if="$viewport.desktop"
               class="guest-reviews__title">{{params.title}}</p>
            <div>
              <Button
                class="guest-reviews__button"
                @click.native="$openPopupAddComment({
                page: $page.type,
                title: $page.type === 'news' || $page.type === 'events' ?
                'Оставить комментарий' : 'Оставить отзыв'
              })"
                v-if="$user.logged"
                :full="$viewport.mobile"
                color="green"
              >
                {{params.button}}
              </Button>
            </div>
          </Column>
          <Column v-if="!$token"
                  class="guest-reviews__unauthorized"
                  :class="$viewport.desktop ? 'grid-4' : ''">
            <p class="guest-reviews__unauthorized-title">Отзывы<br>гостей</p>
            <div>
              <router-link to="/login/keycloak" class="guest-reviews__unauthorized-link"
                           target="_blank">Авторизуйтесь
              </router-link>
              <span>или </span>
              <router-link to="/login/keycloak" class="guest-reviews__unauthorized-link"
                           target="_blank">зарегистрируйтесь
              </router-link>
              <span>чтобы оставить отзыв</span>
            </div>
          </Column>
          <Column v-if="$comments.length > 0"
                  :class="$viewport.desktop ? 'grid-8' : ''"
                  :full="$viewport.mobile">
            <GuestReview :key="review.id"
                         :params="review"
                         v-for="review in reviews"/>
          </Column>
          <Column v-if="$comments.length === 0"
                  :class="$viewport.desktop ? 'grid-8' : ''">
            <Icon class="guest-reviews__unauthorized-icon"
                  viewport="0 0 77 60"
                  xlink="null-comment"/>
            <span class="guest-reviews__unauthorized-text">К {{entityFeedback}} пока нет отзывов.
              <br>Будьте первым!</span>
          </Column>
        </Row>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GuestReviews',

  components: { GuestReview: () => import('../../components/GuestReviews/GuestReview/index') },

  props: {
    params: {},
  },

  data() {
    return {
      textareaMaxCount: 700,
      review: '',
      isYandex: false,
      yandexTarget: {},
      textForEntity: {
        news: 'этой новости',
        event: 'этому событию',
        places: 'этому объекту',
        trips: 'этой экскурсии',
      },
    };
  },

  computed: {
    entityFeedback: vm => vm.textForEntity[vm.$page.type],
    reviews() {
      return this.$comments.filter(item => item.parent_id === null);
    },
    textareaCount() {
      return this.textareaMaxCount - this.review.length;
    },
  },

  methods: {
    setComment() {
      this.$store.commit('OPEN_POPUP', {
        clear: true,
        width: 'auto',
        height: 'auto',
        horizontal: 'center',
        vertical: 'center',
        type: 'PopupCommentOnModeration',
      });
      this.$store.dispatch('SET_COMMENT_TO_SERVER', {
        params: {
          comment: this.review,
        },
        id: this.$route.params.id,
      });
      this.$store.dispatch('GET_COMMENTS_FROM_SERVER', {
        clear: true,
        type: this.$page.type,
        id: this.$route.params.id,
      });
    },
    getComments() {
      this.$store.dispatch('GET_COMMENTS_FROM_SERVER', {
        clear: true,
        type: this.$page.type,
        id: this.$route.params.id,
      });
    },
    initYandexComments() {
      this.$refs.yandexWidget.setCommentsTarget(this.yandexTarget);
    },
  },

  async mounted() {
    if (this.params.yandexSearch) {
      const companies = await this.$getYandexCompaniesByTitle(this.params.yandexSearch);

      if (companies.length) {
        this.isYandex = true;
        this.yandexTarget = companies.shift();
      } else {
        this.getComments();
      }
    } else {
      this.getComments();
    }
  },
};
</script>
